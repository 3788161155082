<template>
  <div class="rate_list_box padding-md text-white">
    <div v-if="$route.path.lastIndexOf('/') === 0">
      <!-- 搜索框 -->
      <!-- <div class="box-card">
        <div class="text item">
          <el-form inline class="padding-top margin-sm-bottom">
            <el-form-item label="用户编号">
              <el-input
                v-model="searchForm.id"
                style="width: 250px"
                clearable
              />
            </el-form-item>
            <el-form-item>
              <el-button
                class="btn-purple"
                icon="el-icon-search"
                :disabled="role >= 1 ? true : false"
                @click="queryUser()"
                v-preventReClick
                >{{ $t("siteList.checkButton") }}</el-button
              >
              <el-button
                class="btn-green"
                icon="el-icon-plus"
                @click="onAdd(1)"
                >{{ $t("deviceList.addEquipment") }}</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div> -->
      <!-- 站点数据信息展示表格 -->
      <div class="table-box">
        <!-- <div class="table-add">
          <el-button
            class="btn-blue"
            icon="el-icon-circle-plus"
            style="
              color: #8eb8f5;
              border: 0px;
              background: transparent;
              font-size: 15px;
            "
            @click="onAdd(1)"
            v-preventReClick
            >新增费率信息</el-button
          >
        </div> -->
        <el-table
          :data="logData"
          style="width: 100%"
          :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
          :row-class-name="tableRowClassName"
          :row-style="{ height: '60px' }"
        >
          <el-table-column label="账号" prop="userId" width="80">
          </el-table-column>
          <el-table-column label="IP地址" prop="ip"> </el-table-column>
          <el-table-column label="所属公司" prop="customerId">
          </el-table-column>
          <el-table-column label="操作" prop="operation"> </el-table-column>
          <el-table-column label="操作时间" prop="createTime">
          </el-table-column>
        </el-table>
        <!-- 翻页 -->
        <div class="block" style="margin-top: 15px">
          <pagination
            :total="countNum"
            v-on:getCurrent="changeCurrent"
            v-on:getPageSize="changePage"
          ></pagination>
        </div>
      </div>
    </div>
    <user-update ref="userUpdate" @refresh="getLog"> </user-update>
    <!-- <customer-update ref="customerUpdate" @refresh="getUser"></customer-update> -->
  </div>
</template>

<script>
import pagination from "../../components/pagination.vue";
import { logQuery, userDel } from "../../api/user";
// import DeviceInfo from "../../components/deviceInfo.vue";
import i18n from "@/lang";
import UserUpdate from "./components/userUpdate.vue";
// import CustomerUpdate from "@/components/customerUpdate.vue";
export default {
  components: { pagination, UserUpdate },
  data() {
    return {
      searchForm: {
        id: "",
        name: "",
      },
      logData: [],
      searchRules: {
        siteId: [
          { required: false, message: "请输入站点ID", trigger: "blur" },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/,
            message: "用户ID以字母开头，且只能包含英文字母、数字、下划线",
          },
        ],
      },
      object: {
        pageNumber: 1,
        pageSize: 10,
      },
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页的数据条数
      role: 0, //角色
      countNum: 0,
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getLog();
    },
    queryUser() {
      logQuery({
        id: this.searchForm.id,
        para: { name: this.searchForm.name },
      })
        .then((res) => {
          console.log(res.data.data.list, "88888888");
          this.countNum = res.data.data.countNum;
          this.logData = res.data.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLog() {
      // console.log(obj);
      logQuery(this.object)
        .then((res) => {
          console.log(res.data.data);
          this.countNum = res.data.data.countNum;
          this.logData = res.data.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onAdd(type, row = {}) {
      this.$refs.userUpdate.open(type, row);
    },
    onDelete(row) {
      console.log(row.id);
      this.$confirm(
        i18n.t("prompt.deletePromptInfo"),
        i18n.t("prompt.promptInfo"),
        {
          confirmButtonText: i18n.t("prompt.confirm"),
          cancelButtonText: i18n.t("prompt.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          userDel({ id: row.id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: i18n.t("prompt.operateSuccessfully"),
              });
              this.getCustomer();
            } else {
              this.$message({
                type: "error",
                message: i18n.t("prompt.operationFailure") + res.data.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: i18n.t("prompt.OperationCancelled"),
          });
        });
    },
    changeCurrent(data) {
      this.object.pageNumber = data;
      this.currentPage = data;
      console.log(this.object);
      this.getUser();
    },
    changePage(data) {
      this.object.pageSize = data;
      this.pageSize = data;
      console.log(this.object);
      this.getUser();
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "";
      } else {
        return "warning-row";
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.rate_list_box{
    width: 100%;
    box-sizing: border-box;
    height: calc(100vh - 130px);
    overflow: auto;
}
// .table-box{
//   padding: 20px;
//     background: linear-gradient(45deg, #35478a, #162257);
//     border-radius: 10px;
// }
.table-box{
  padding: 20px;
    // background: linear-gradient(45deg, #35478a, #162257);
    background: #102135
    border-radius: 10px;
}
.table-add{
  margin-bottom: 20px
}
/deep/.el-table, .el-table tr, .el-table td, .el-table th {
    background-color: transparent!important;
}
// 表格斑马自定义颜色
::v-deep .el-table__row.warning-row {
  background: #17273b !important;
}
::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
  border-bottom: 1px solid #27344b
}
</style>
